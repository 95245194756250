import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.PROCESS_LEASE_SERVER_PREFIX}/deed-sign-off`;
const endpoints = {
  list: () => `${RESOURCE_ENDPOINT}`,
  getById: (id: any) => `${RESOURCE_ENDPOINT}/${id}`,
  getByWhere: () => `${RESOURCE_ENDPOINT}/getByWhere`,
  create: () => `${RESOURCE_ENDPOINT}`,
  update: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
  updatePartial: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
  delete: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
  bulk: () => `${RESOURCE_ENDPOINT}/bulk`,
  dropdown: () => `${RESOURCE_ENDPOINT}/dropdown`,
  daakCreate: () => `process-lease/entohi/daak-create`,
  daakReceiverDeskId: () => `${CONSTANT_CONFIG.CORE_SERVER_PREFIX}/enothi-daak-receiver-desk/get-front-desk`,
}

export default class DeedSignOffApi {
  public list = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.list();
    return HttpService.get(url, params, headers);
  }

  public getById = (id: any): AxiosPromise<any> => {
    const url = endpoints.getById(id);
    return HttpService.get(url);
  }

  public getByWhere = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getByWhere();
    return HttpService.get(url, params, headers);
  }

  public create = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.create();
    return HttpService.post(url, payload, params, headers);
  }

  public update = (id: any, payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.update(id);
    return HttpService.put(url, payload, params, headers);
  }

  public updatePartial = (id: any, payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.updatePartial(id);
    return HttpService.patch(url, payload, params, headers);
  }

  public delete = (id: any, params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.delete(id);
    return HttpService.delete(url, params, headers);
  }

  public bulk = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.bulk();
    return HttpService.post(url, payload, params, headers);
  }

  public dropdown = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.dropdown();
    return HttpService.get(url, params, headers);
  }
  public daakCreate = (
    payload = {},
    params = {},
    headers = {}
  ): AxiosPromise<any> => {
    const url = endpoints.daakCreate();
    return HttpService.post(url, payload, params, headers);
  };

  public daakReceiverDeskId = (
    payload = {},
    params = {},
    headers = {}
  ): AxiosPromise<any> => {
    const url = endpoints.daakReceiverDeskId();
    return HttpService.post(url, payload, params, headers);
  };

}
