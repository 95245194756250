import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import {HttpService} from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.PROCESS_LEASE_SERVER_PREFIX}/report`;
const endpoints = {
    getReportProcessItemList: () => `${RESOURCE_ENDPOINT}/process-item-report`,
    getRegularLeaseApplicationListReport: () => `${RESOURCE_ENDPOINT}/regular-lease-application-report`,
    getSponsorshipLeaseApplicationListReport: () => `${RESOURCE_ENDPOINT}/sponsorship-lease-application-report`,
    getLeaseApplicationDetailListReport: () => `${RESOURCE_ENDPOINT}/lease-application-detail-list-report`,
    getLabwiseLeaseSummaryListReport: () => `${RESOURCE_ENDPOINT}/lab-wise-lease-summary-list-report`,
    getProcessItemCategorywiseLeaseSummaryListReport: () => `${RESOURCE_ENDPOINT}/process-item-category-wise-lease-summary-list-report`,
    getScientistWiseLeaseSummaryReport: () => `${RESOURCE_ENDPOINT}/scientist-wise-lease-summary-report`,
    getExpenditureSummaryListReport: () => `${RESOURCE_ENDPOINT}/expenditure-summary-list-report`,
    getIndividualCollectionExpenditureReport: () => `${RESOURCE_ENDPOINT}/individual-collection-expenditure-report`,
    getApplicationTrackingReport: () => `${RESOURCE_ENDPOINT}/application-tracking-report`,
    getApplicationTypeWiseLeaseSummaryReport: () => `${RESOURCE_ENDPOINT}/application-type-wise-lease-summary-report`,
    getLeaseTerminationReport: () => `${RESOURCE_ENDPOINT}/lease-termination-list-report`,
    getLeaseDefaulterReport: () => `${RESOURCE_ENDPOINT}/defaulter-list-report`,
    getLeaseBlackReport: () => `${RESOURCE_ENDPOINT}/black-list-report`,
    getCollectionSummaryReport: () => `${RESOURCE_ENDPOINT}/collection-summary-report`,
    getLeaseWiseCompanyReport: () => `${RESOURCE_ENDPOINT}/lease-wise-company-report`,
}

export default class ProcessLeaseReportApi {

    public getReportProcessItemList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getReportProcessItemList();
        return HttpService.get(url, params, headers);
    }

    public getRegularLeaseApplicationListReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getRegularLeaseApplicationListReport();
        return HttpService.get(url, params, headers);
    }

    public getSponsorshipLeaseApplicationListReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getSponsorshipLeaseApplicationListReport();
        return HttpService.get(url, params, headers);
    }

    public getLeaseApplicationDetailListReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getLeaseApplicationDetailListReport();
        return HttpService.get(url, params, headers);
    }

    public getLabwiseLeaseSummaryListReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getLabwiseLeaseSummaryListReport();
        return HttpService.get(url, params, headers);
    }

    public getProcessItemCategorywiseLeaseSummaryListReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getProcessItemCategorywiseLeaseSummaryListReport();
        return HttpService.get(url, params, headers);
    }

    public getScientistWiseLeaseSummaryReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getScientistWiseLeaseSummaryReport();
        return HttpService.get(url, params, headers);
    }

    public getExpenditureSummaryListReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getExpenditureSummaryListReport();
        return HttpService.get(url, params, headers);
    }

    public getIndividualCollectionExpenditureReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getIndividualCollectionExpenditureReport();
        return HttpService.get(url, params, headers);
    }

    public getApplicationTrackingReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getApplicationTrackingReport();
        return HttpService.get(url, params, headers);
    }

    public getApplicationTypeWiseLeaseSummaryReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getApplicationTypeWiseLeaseSummaryReport();
        return HttpService.get(url, params, headers);
    }

    public getLeaseDefaulterReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getLeaseDefaulterReport();
        return HttpService.get(url, params, headers);
    }

    public getLeaseBlackReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getLeaseBlackReport();
        return HttpService.get(url, params, headers);
    }

    public getLeaseTerminationReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getLeaseTerminationReport();
        return HttpService.get(url, params, headers);
    }

    public getLeaseWiseCompanyReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getLeaseWiseCompanyReport();
        return HttpService.get(url, params, headers);
    }

    public getCollectionSummaryReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getCollectionSummaryReport();
        return HttpService.get(url, params, headers);
    }
}
