import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.PROCESS_LEASE_SERVER_PREFIX}/export`
const endpoints = {
    exportProcessItemListReport: () => `${RESOURCE_ENDPOINT}/process-item-list-report-export`,

    exportRegularLeaseApplicationListReport: () => `${RESOURCE_ENDPOINT}/regular-lease-application-list-report-export`,

    exportSponsorshipLeaseApplicationListReport: () => `${RESOURCE_ENDPOINT}/sponsorship-lease-application-list-report-export`,

    exportLeaseApplicationDetailListReport: () => `${RESOURCE_ENDPOINT}/lease-application-detail-list-report-export`,

}

export default class ExportProcessLeaseApi {
    public exportProcessItemListReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportProcessItemListReport();
        return HttpService.post(url, payload, params, headers);
    }

    public exportRegularLeaseApplicationListReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportRegularLeaseApplicationListReport();
        return HttpService.post(url, payload, params, headers);
    }

    public exportSponsorshipLeaseApplicationListReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportSponsorshipLeaseApplicationListReport();
        return HttpService.post(url, payload, params, headers);
    }

    public exportLeaseApplicationDetailListReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportLeaseApplicationDetailListReport();
        return HttpService.post(url, payload, params, headers);
    }


}

