// CDN Server
import _FileApi from "./Cdn/File.api";
import _CsvApi from "./Cdn/Csv.api";

// Auth Server
import _GroupApi from "./Auth/Group.api";
import _OauthApi from "./Auth/Oauth.api";
import _PermissionApi from "./Auth/Permission.api";
import _ResourceApi from "./Auth/Resource.api";
import _RoleApi from "./Auth/Role.api";
import _ScopeApi from "./Auth/Scope.api";
import _UserApi from "./Auth/User.api";
import _WorkspaceApi from "./Auth/Workspace.api";
import _OrganizationApi from "./Auth/Organization.api";
import _OrganogramApi from "./Auth/Organogram.api";
import _EmployeeApi from "./Auth/Employee.api";
import _RequestAccessApi from "./Auth/RequestAccess.api";
import _ApplicantProfileApi from "./Auth/ApplicantProfile.api";

// Core Server
import _NotificationApi from "./Core/Notification.api";
import _LookupApi from "./Core/Lookup.api";
import _LocationApi from "./Core/Location.api";
import _ComponentApi from "./Core/Component.api";
import _PostApi from "./Core/Post.api";
import _OptionApi from "./Core/Option.api";
import _TemplateApi from "./Core/Template.api";
import _HelpApi from "./Core/Help.api";
import _ActivityLogApi from "./Core/ActivityLog.api";
import _AuditLogApi from "./Core/AuditLog.api";
import _ExportScmApi from "./Core/ExportScm.api";
import _ImportScmApi from "./Core/ImportScm.api";
import _FackApi from "./Core/Fack.api";
import _OrganizationCategoryApi from "./Core/OrganizationCategory.api";
import _DepartmentApi from "./Core/Department.api";
import _ApiResourceApi from "./Core/ApiResource.api";
import _FinancialYearApi from "./Core/FinancialYear.api";
import _EnumApi from "./Core/Enum.api";
import _BankApi from "./Core/Bank.api";
import _BankBranchApi from "./Core/BankBranch.api";
import _DesignationApi from "./Auth/Designation.api";
import _DepartmentSubjectApi from "./Core/DepartmentSubject.api";
import _FacultySchoolApi from "./Core/FacultySchool.api";
import _InstituteOrganizationApi from "./Core/InstituteOrganization.api";
import _AutomationRuleApi from "./Core/AutomationRule.api";
import _WorkflowApprovalApi from "./Core/WorkflowApproval.api";
import _WorkflowStepApi from "./Core/WorkflowStep.api";
import _WorkflowTransitionApi from "./Core/WorkflowTransition.api";
import _AttachmentTypeApi from "./Core/AttachmentType.api";
import _ServiceMappingApi from "./Core/ServiceMapping.api";
import _ServicesApi from "./Core/Services.api";
import _ServiceAssignmentApi from "./Core/ServiceAssignment.api";
import _NoticeApi from "./Core/Notice.api";
import _AdvertisementApi from "./Core/Advertisement.api";
import _OwnerShipTypeApi from "./Core/OwnerShipType.api";
import _WorkflowBulkTransitionApi from "./Core/WorkflowBulkTransition.api";
import _CommitteeMemberInfoApi from "./Core/CommitteeMemberInfo.api";
import _LeaseCommitteeApi from "./Core/Committee.api";
import _PaymentApi from "./Core/Payment.api";

// CMS Server
import _PageApi from "./Cms/Page.api";
import _BlocksApi from "./Cms/Blocks.api";
import _MenuApi from "./Cms/Menu.api";
import _MediaApi from "./Cms/Media.api";
import _CategoriesApi from "./Cms/Categories.api";

// ProcessLease
import _ProcessItemApi from "./ProcessLease/ProcessItem.api";
import _ProcessLeasedOutApi from "./ProcessLease/ProcessLeasedOut.api";

import _ProcessItemCategoryApi from "./ProcessLease/ProcessItemCategory.api";
import _TemplatesApi from "./ProcessLease/Templates.api";
import _ExpenditureHeadApi from "./ProcessLease/ExpenditureHead.api";
import _InstructionApi from "./ProcessLease/Instruction.api";
import _AttachmentMappingApi from "./ProcessLease/AttachmentMapping.api";
import _NoteApi from "./ProcessLease/Note.api";
import _ProcessLeaseApplicationApi from "./ProcessLease/ProcessLeaseApplication.api";
import _LetterApi from "./ProcessLease/Letter.api";
import _DeedPreparationApi from "./ProcessLease/DeedPreparation.api";
import _DeedSignOffApi from "./ProcessLease/DeedSignOff.api";
import _InspectionFieldVisitApi from "./ProcessLease/InspectionFieldVisit.api";
import _FixationMeetingResolutionApi from "./ProcessLease/FixationMeetingResolution.api";
import _PaymentInformationApi from "./ProcessLease/PaymentInformation.api";
import _RefundApi from "./ProcessLease/Refund.api";
import _ExpenditureApi from "./ProcessLease/Expenditure.api";
import _RequestApplicationApi from "./ProcessLease/RequestApplication.api";
import _MonitoringVisitReportApi from "./ProcessLease/MonitoringFieldVisit.api";
import _ProcessLeaseTerminationApi from "./ProcessLease/ProcessLeaseTermination.api";
import _ResearchProposalSubmissionApi from "./ProcessLease/ResearchProposalSubmission.api";
import _ExportProcessLeaseApi from "./ProcessLease/ExportProcessLeaseReport.api";
import _ResearchProposalCompletionApi from "./ProcessLease/ResearchProposalCompletion.api";
import _ProcessLeaseReportApi from "./ProcessLease/ProcessLeaseReportApi.api";
import _MeetingResolutionApi from "./ProcessLease/MeetingResolution.api";
import _ScientistClaimApi from "./ProcessLease/ScientistClaim.api";
import _FeeApi from "./ProcessLease/Fee.api";

// CND Server
export const FileApi = new _FileApi();
export const CsvApi = new _CsvApi();

// Auth Server
export const GroupApi = new _GroupApi();
export const OauthApi = new _OauthApi();
export const PermissionApi = new _PermissionApi();
export const ResourceApi = new _ResourceApi();
export const RoleApi = new _RoleApi();
export const ScopeApi = new _ScopeApi();
export const UserApi = new _UserApi();
export const WorkspaceApi = new _WorkspaceApi();
export const OrganizationApi = new _OrganizationApi();
export const OrganogramApi = new _OrganogramApi();
export const EmployeeApi = new _EmployeeApi();
export const RequestAccessApi = new _RequestAccessApi();
export const ApplicantProfileApi = new _ApplicantProfileApi();

// Core Server
export const FackApi = new _FackApi();
export const NotificationApi = new _NotificationApi();
export const LookupApi = new _LookupApi();
export const LocationApi = new _LocationApi();
export const ComponentApi = new _ComponentApi();
export const PostApi = new _PostApi();
export const OptionApi = new _OptionApi();
export const TemplateApi = new _TemplateApi();
export const HelpApi = new _HelpApi();
export const ActivityLogApi = new _ActivityLogApi();
export const AuditLogApi = new _AuditLogApi();
export const ExportScmApi = new _ExportScmApi();
export const ImportScmApi = new _ImportScmApi();
export const OrganizationCategoryApi = new _OrganizationCategoryApi();
export const DepartmentApi = new _DepartmentApi();
export const ApiResourceApi = new _ApiResourceApi();
export const FinancialYearApi = new _FinancialYearApi();
export const EnumApi = new _EnumApi();
export const BankApi = new _BankApi();
export const BankBranchApi = new _BankBranchApi();
export const DesignationApi = new _DesignationApi();
export const DepartmentSubjectApi = new _DepartmentSubjectApi();
export const FacultySchoolApi = new _FacultySchoolApi();
export const InstituteOrganizationApi = new _InstituteOrganizationApi();
export const AutomationRuleApi = new _AutomationRuleApi();
export const WorkflowApprovalApi = new _WorkflowApprovalApi();
export const WorkflowStepApi = new _WorkflowStepApi();
export const WorkflowTransitionApi = new _WorkflowTransitionApi();
export const AttachmentTypeApi = new _AttachmentTypeApi();
export const ServiceMappingApi = new _ServiceMappingApi();
export const ServicesApi = new _ServicesApi();
export const ServiceAssignmentApi = new _ServiceAssignmentApi();
export const NoticeApi = new _NoticeApi();
export const AdvertisementApi = new _AdvertisementApi();
export const OwnerShipTypeApi = new _OwnerShipTypeApi();
export const WorkflowBulkTransitionApi = new _WorkflowBulkTransitionApi();
export const CommitteeMemberInfoApi = new _CommitteeMemberInfoApi();
export const LeaseCommitteeApi = new _LeaseCommitteeApi();
export const PaymentApi = new _PaymentApi();

// CMS Server
export const PageApi = new _PageApi();
export const BlocksApi = new _BlocksApi();
export const MenuApi = new _MenuApi();
export const MediaApi = new _MediaApi();
export const CategoriesApi = new _CategoriesApi();

// Process Lease Server
export const ProcessItemApi = new _ProcessItemApi();
export const ProcessLeasedOutApi = new _ProcessLeasedOutApi();
export const ProcessItemCategoryApi = new _ProcessItemCategoryApi();
export const TemplatesApi = new _TemplatesApi();
export const ExpenditureHeadApi = new _ExpenditureHeadApi();
export const InstructionApi = new _InstructionApi();
export const AttachmentMappingApi = new _AttachmentMappingApi();
export const NoteApi = new _NoteApi();
export const ProcessLeaseApplicationApi = new _ProcessLeaseApplicationApi();
export const LetterApi = new _LetterApi();
export const InspectionFieldVisitApi = new _InspectionFieldVisitApi();
export const FixationMeetingResolutionApi = new _FixationMeetingResolutionApi();
export const DeedPreparationApi = new _DeedPreparationApi();
export const DeedSignOffApi = new _DeedSignOffApi();
export const PaymentInformationApi = new _PaymentInformationApi();
export const RefundApi = new _RefundApi();
export const ExpenditureApi = new _ExpenditureApi();
export const RequestApplicationApi = new _RequestApplicationApi();
export const MonitoringFieldVisitApi = new _MonitoringVisitReportApi();
export const ProcessLeaseTerminationApi = new _ProcessLeaseTerminationApi();
export const ResearchProposalSubmissionApi = new _ResearchProposalSubmissionApi();
export const ProcessLeaseReportApi = new _ProcessLeaseReportApi();
export const ExportProcessLeaseApi = new _ExportProcessLeaseApi();
export const ResearchProposalCompletionApi = new _ResearchProposalCompletionApi();
export const MeetingResolutionApi = new _MeetingResolutionApi();
export const ScientistClaimApi = new _ScientistClaimApi();
export const FeeApi = new _FeeApi();
